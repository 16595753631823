// md - small dekstop

.wrapper {
	padding: 0 50px;
}

// header
.header {
	padding: 40px 0 30px;
}

// footer
.footer {
	padding: 40px 0 50px;
	.footer-cols {
		.footer-col {
			&.footer-col-copyright {
				right: 50px;
			}
		}
	}
}

// main
.main {
	padding: 80px 0;
	.zone-1 {
		.text {
			width: 420px;
			//top: 25px;
			right: 70px;
		}
		.image {
			//width: 390px;
		}
	}
	.zone-2 {
		margin-top: -10px;
		.image {
			img {
				//width: 290px;
				margin-right: 40px;
				//float: right;
			}
		}
		.text-1 {
			//width: 500px;
			//position: absolute;
			//top: 120px;
			right: 305px;
		}
		/*.text-2 {
			width: 500px;
			position: absolute;
			left: 45px;
			top: 260px;
		}*/
	}
	.zone-3 {
		//margin-top: -110px;
		/*.image {
			width: 309px;
			margin-left: 80px;
		}*/
		.text {
			width: 460px;
			//right: 0;
			//bottom: 65px;
		}
	}
}
// sm - tablet

.social-icons {
	a {
		font-size: 16px;
		line-height: 30px;
		width: 30px;
		height: 30px;
		margin-left: 7px;
	}
}

.wrapper {
	padding: 0 40px;
}

// header
.header {
	padding: 30px 0 20px;
	.logo {
		svg.logo-svg {
			width: 250px;
			height: 87px;
		}
	}
}

// footer
.footer {
	padding: 30px 0 40px;
	.footer-cols {
		.footer-col {
			width: 50%;
			&.footer-col-copyright {
				width: 100%;
				padding-top: 30px;
				float: none;
				clear: both;
				position: static;
			}
		}
	}
}

// main
.main {
	padding: 50px 0;
	.zone-1 {
		.text {
			width: 350px;
			//top: 25px;
			right: 20px;
		}
		.image {
			width: 350px;
		}
	}
	.zone-2 {
		margin-top: 10px;
		.image {
			img {
				width: 260px;
				margin-right: 20px;
			}
		}
		.text-1 {
			width: 360px;
			//position: absolute;
			top: 90px;
			right: 260px;
		}
		.text-2 {
			width: 360px;
			left: 0;
			top: 335px;
		}
	}
	.zone-3 {
		margin-top: 255px;
		.image {
			width: 280px;
			margin-left: 40px;
		}
		.text {
			width: 280px;
			right: 0;
			bottom: 25px;
		}
	}
}
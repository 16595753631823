/* imports */
@import 'variables';
@import '../vendor/bootstrap-custom';
@import 'mixins';
@import 'pagefold.scss';

// Haal googlefonts altijd lokaal binnen, zorgt voor een snellere laadtijd en caching mogelijkheden: http://localfont.com/
// Gebruik image-url( 'file.png' )
// font-face( $name, $filename, $weight: null, $style: null, $exts: eot woff2 woff ttf svg)
@include font-face( "Sanchez", "sanchez-v5-latin-regular", 400, normal, eot woff woff2 ttf svg );
@include font-face( "Sanchez", "sanchez-v5-latin-italic", 400, italic, eot woff woff2 ttf svg );
//@include font-face( "icomoon", "icomoon", 400, normal, eot woff woff2 ttf svg );

::selection {
	color: $white;
	background-color: $green;
}
::-moz-selection {
	color: $white;
	background-color: $green;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	color: $red;
}
::-moz-placeholder { /* Firefox 19+ */
	color: $red;
}
:-ms-input-placeholder { /* IE 10+ */
	color: $red;
}
:-moz-placeholder { /* Firefox 18- */
	color: $red;
}

// svgs
$svgs:
	( 'logo', 300px, 104px ),
;

span.svg-graphic {
	line-height: 0;
	vertical-align: middle;
	white-space: normal;
	display: inline-block;
	@each $svg in $svgs {
		&.#{nth( $svg, 1 )} {
			&.is-responsive {
				padding-bottom: 100% * ( nth( $svg, 3 ) / nth( $svg, 2 ) );
			}
		}
		svg {
			&.#{nth( $svg, 1 )}-svg {
				width: nth( $svg, 2 );
				height: nth( $svg, 3 );
			}
		}
	}
	&.is-responsive {
		position: relative;
		display: block;
		width: 100%;
		height: 0;
		padding: 0;
		svg {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}

// bp helpers
.bp-helper {
	font-size: 10px;
	line-height: 22px;
	color: $white;
	text-align: center;
	padding: 0 5px;
	height: 20px;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 1000000;
	background-color: $red;
}

/* general */
html, body {
	font-family: $font;
	font-size: 14px;
	line-height: 22px;
	color: $grey;
	font-weight: $regular;
	background-color: $lightgrey;
	-webkit-text-size-adjust: none;
}
body {
	min-height: 10px !important;
}

a {
	color: $grey;
	outline: 0;
	text-decoration: none;
	transition: color 0.4s $ease_out;
	&:link, &:visited, &:focus {
		color: $grey;
		outline: 0;
		text-decoration: none;
	}
	&:hover {
		color: $black;
		outline: 0;
		//text-decoration: underline;
	}
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
	margin-top: 0;
}
h1, .h1 {
	font-size: 30px;
	line-height: 36px;
	font-weight: $regular;
	margin-bottom: 40px;
	a,
	a:link,
	a:visited {
		color: $grey;
		text-decoration: none;
	}
}
h2, .h2 {
	font-family: $second_font;
	font-size: 20px;
	line-height: 26px;
	color: $black;
	font-weight: $bold;
	margin-bottom: 5px;
	a,
	a:link,
	a:visited {
		color: $grey;
		text-decoration: none;
	}
}

b,
strong {
	font-weight: $bold;
}

.wysiwyg-content {
	p {
		margin: 0 0 20px;
		padding: 0;
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.social-icons {
	a {
		font-size: 20px;
		line-height: 36px;
		color: $white;
		text-align: center;
		display: block;
		width: 36px;
		height: 36px;
		margin-left: 10px;
		float: left;
		border-radius: 50%;
		background-color: $grey;
		transition: background 0.4s $ease_out;
		&:first-child {
			margin-left: 0;
		}
		&:hover {
			background-color: $black;
		}
	}
}

.wrapper {
	max-width: 1150px;
	margin: 0 auto;
	padding: 0 70px;
}

// header
.header {
	text-align: center;
	padding: 60px 0 50px;
	background-color: $white;
	.wrapper {
		position: relative;
	}
	.social-icons {
		height: 36px;
		margin-top: -18px;
		position: absolute;
		right: 70px;
		top: 50%;
	}
}

// footer
.footer {
	padding: 60px 0 70px;
	background-color: $white;
	.footer-cols {
		position: relative;
		overflow: hidden;
		.footer-col {
			width: 40%;
			float: left;
			&.footer-col-copyright {
				font-size: 12px;
				line-height: 20px;
				width: auto;
				position: absolute;
				right: 70px;
				bottom: 0;
			}
			.wysiwyg-content {
				a {
					text-decoration: underline;
					&:hover {
						text-decoration: none;
					}
				}
			}
			.social-icons {
				margin-top: 10px;
			}
		}
	}
}

// main
.main {
	padding: 110px 0;
	img {
		max-width: 100%;
		height: auto;
	}
	.zone-1 {
		position: relative;
		.text {
			width: 500px;
			position: absolute;
			top: 30px;
			right: 100px;
		}
		.image {
			width: 433px;
		}
	}
	.zone-2 {
		margin-top: -30px;
		position: relative;
		z-index: 10;
		.image {
			overflow: hidden;
			img {
				width: 290px;
				margin-right: 60px;
				float: right;
			}
		}
		.text-1 {
			text-align: right;
			width: 500px;
			position: absolute;
			top: 120px;
			right: 325px;
		}
		.text-2 {
			width: 500px;
			position: absolute;
			left: 45px;
			top: 360px;
		}
	}
	.zone-3 {
		margin-top: 140px;
		position: relative;
		.image {
			width: 309px;
			margin-left: 80px;
		}
		.text {
			width: 500px;
			position: absolute;
			right: 0;
			bottom: 50px;
		}
	}
}

// media queries
/*@media only screen and ( min-width: 1200px ) {
	@import 'mq/_xl';
}*/

@media only screen and ( max-width: 1379px ) {
	@import 'mq/_lg';
}

@media only screen and ( max-width: 1199px ) {
	@import 'mq/_md';
}

@media only screen and ( max-width: 991px ) {
	@import 'mq/_sm';
}

@media only screen and ( max-width: 767px ) {
	@import 'mq/_xs';
}

@media only screen and ( max-width: 660px ) {
	@import 'mq/_xxs';
}

// print
@media print {}

/* svg's retina */
@media
only screen and ( -webkit-min-device-pixel-ratio: 2 ),
only screen and ( -o-min-device-pixel-ratio: 2/1 ),
only screen and ( min-device-pixel-ratio: 2 ),
only screen and ( min-resolution: 192dpi ),
only screen and ( min-resolution: 2dppx ) {

	@import 'mq/_retina';

}
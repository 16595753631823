// xxs - smartphone

.wrapper {
	padding: 0 30px;
}

.social-icons {
	font-size: 0;
	a {
		font-size: 14px;
		line-height: 26px;
		width: 26px;
		height: 26px;
		margin-left: 4px;
	}
}

// header
.header {
	padding: 20px 0 15px;
	.logo {
		svg.logo-svg {
			width: 200px;
			height: 69px;
		}
	}
	.social-icons {
		text-align: center;
		margin-top: 20px;
		position: static;
		a {
			display: inline-block;
			float: none;
		}
	}
}

// footer
.footer {
	padding: 30px 0 30px;
	.footer-cols {
		.footer-col {
			width: 100%;
			padding-top: 30px;
			&:first-child {
				padding-top: 0;
			}
		}
	}
}

// main
.main {
	padding: 30px 0;
}
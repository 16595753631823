// xs - smartphone

// main
.main {
	padding: 50px 0;
	img {
		width: 100% !important;
	}
	.zone-1 {
		.text {
			width: 100%;
			position: static;
		}
		.image {
			width: 100%;
			margin-top: 30px;
		}
	}
	.zone-2 {
		margin-top: 30px;
		.image {
			margin-top: 30px;
			img {
				margin-right: 0;
				float: none;
			}
		}
		.text-1 {
			text-align: left;
			width: 100%;
			position: static;
		}
		.text-2 {
			width: 100%;
			margin-top: 30px;
			position: static;
		}
	}
	.zone-3 {
		margin-top: 30px;
		.image {
			width: 100%;
			margin-left: 0;
		}
		.text {
			width: 100%;
			margin-top: 30px;
			position: static;
		}
	}
}